/* .image-gallery-slide .image-gallery-description { */
  /* bottom: 0 !important; */
  /* width: 100% !important; */
  /* text-align: start !important; */
  /* background: none; */
  /* max-height: 1rem !important; */
  /* padding: 0.3rem; */
  /* font-family: "Roboto", sans-serif !important; */
/* } */

/* @media only screen and (max-width: 767.98px) {
  .image-gallery-slide .image-gallery-description {
    font-size: 0.7rem;
  }
} */


 @media only screen and (max-width: 767.98px) {
  /* .image-gallery-slide img {
    width: 100%;
  } */
  .image-gallery-slide-wrapper {
    background-color: #ebecf0;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .image-gallery-slide img {
     height: 500px;
    /* height: 500px; */
  }
  .image-gallery-slide-wrapper {
    background-color: #ebecf0;
    padding: 1rem;
  }
  /* .image-gallery-content.fullscreen > .image-gallery-slide img {
    height: 300px;
  } */
}

@media only screen and (min-width: 1200px) {
  .image-gallery-slide img {
     height: 600px;
    /* height: 500px; */
  }

  /* .image-gallery-content.fullscreen > .image-gallery-slide img {
    height: 300px;
  } */
}

